import PropTypes from "prop-types";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
} from "reactstrap";
import { topNavbarBreakpoint } from "../../config";
import { breakpoints } from "../../helpers/utils";
import { landingRoutes } from "../../routes";
import NavbarDropdown from "./NavbarDropdown";

const ContactForm = () => (
  <Form>
    <FormGroup>
      <Label for="name">Nombre</Label>
      <Input id="name" />
    </FormGroup>
    <FormGroup>
      <Label for="emailModal">Correo electrónico</Label>
      <Input id="emailModal" type="email" />
    </FormGroup>
    <FormGroup>
      <Label for="question">Mensaje</Label>
      <Input type="textarea" id="question" rows="4" />
    </FormGroup>
  </Form>
);

const NavbarTopDropDownMenus = ({ setNavbarCollapsed }) => {
  //const components = [componentRoutes, pluginRoutes, utilityRoutes];
  //const pages = [pageRoutes,/* kanbanRoutes,*/ widgetsRoutes, /*chatRoutes, emailRoutes, ECommerceRoutes*/];
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);
  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
  };

  return (
    <>
      <NavbarDropdown
        title={landingRoutes.name}
        items={landingRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link" onClick={toggleModal} to={false}>
          Contacto
        </NavLink>
      </NavItem>
      {/* <NavItem onClick={handleSetNavbarCollapsed}>
      <NavLink className="nav-link" to="/registro">
         Afilia tu negocio
        </NavLink>
      </NavItem> */}
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>Contáctanos</ModalHeader>
        <ModalBody>
          <ContactForm />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" size="sm" onClick={toggleModal}>
            Cerrar
          </Button>
          <Button color="primary" size="sm" onClick={toggleModal}>
            Enviar pregunta
          </Button>
        </ModalFooter>
      </Modal>
      {/*
      <NavbarDropdown title={pageRoutes.name} items={pages} handleSetNavbarCollapsed={handleSetNavbarCollapsed} />
      <NavbarDropdownComponents
        title={componentRoutes.name}
        items={components}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      <NavbarDropdown
        title={authenticationRoutes.name}
        items={authenticationRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />

      <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link" to="/documentation">
          Documentation
        </NavLink>
      </NavItem>*/}
      {/* <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link" to="/documentation">
          Sobre nosotros
        </NavLink>
      </NavItem> */}
      {/* <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link" to="/documentation">
          Contacto
        </NavLink>
      </NavItem> */}
    </>
  );
};

NavbarTopDropDownMenus.propTypes = {
  setNavbarCollapsed: PropTypes.func.isRequired,
};

export default NavbarTopDropDownMenus;
