import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { CardHeader, Col, Row } from "reactstrap";

const Title = ({ titleTag: TitleTag, className, breakPoint, children, search }) => (
  <TitleTag
    className={classNames(
      {
        "mb-0": !breakPoint,
        [`mb-${breakPoint}-0`]: !!breakPoint,
      },
      className
    )}
  >
    {children}
  </TitleTag>
);

Title.propsType = {
  breakPoint: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  children: PropTypes.node,
  search: PropTypes.bool,
};

Title.defaultProps = { titleTag: "h5" };

const FalconCardHeader = ({
  title,
  light,
  titleTag,
  titleClass,
  className,
  breakPoint,
  children,
  search,
}) => (
  <CardHeader className={classNames({ "bg-light w-100": light }, className)}>
    {children ? (
      <Row className="align-items-center w-100 justify-content-between m-0">
        {search ? (
          <Col className="align-items-center p-0" xs={12} sm={12} md={4}>
            <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
              {title}
            </Title>
          </Col>
        ) : (
          <Col className="align-items-center p-0">
            <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
              {title}
            </Title>
          </Col>
        )}

        {search ? (
          <Col
            md={8}
            sm={12}
            xs={12}
            // {...{ [breakPoint ? breakPoint : "xs"]: "auto" }}
            className={`text${breakPoint ? `-${breakPoint}` : ""}-right p-0`}
          >
            {children}
          </Col>
        ) : (
          <Col
            {...{ [breakPoint ? breakPoint : "xs"]: "auto" }}
            className={`text${breakPoint ? `-${breakPoint}` : ""}-right p-0`}
          >
            {children}
          </Col>
        )}
      </Row>
    ) : (
      <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
        {title}
      </Title>
    )}
  </CardHeader>
);

FalconCardHeader.propTypes = {
  title: PropTypes.node.isRequired,
  light: PropTypes.bool,
  breakPoint: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  titleClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

FalconCardHeader.defaultProps = { light: true };

export default FalconCardHeader;
