import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import ListGroup from "reactstrap/es/ListGroup";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import AppContext from "../../context/Context.js";
import FalconCardHeader from "../common/FalconCardHeader";
import Notification from "../notification/Notification";

const NotificationDropdown = () => {
  const [newNotifications, setNewNotifications] = useState([]);
  const [earlierNotifications, setEarlierNotifications] = useState([]);
  const [noNotifications, setNoNotifications] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { notifications, setNotifications, uid } = useContext(AppContext);
  const [isAllRead, setIsAllRead] = useState(true);
  const firestore = firebase.firestore();
  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const markAsRead = (e) => {
    e.preventDefault();

    if (newNotifications.length !== 0) {
      var promises = [];

      newNotifications.forEach((notification) => {
        const markAsRead = firestore
          .collection("posUsers")
          .doc(uid)
          .collection("notifications")
          .doc(notification.id)
          .update({ read: true });

        promises.push(markAsRead);
      });

      Promise.all(promises)
        .then(() => {
          setNotifications(
            notifications.map((notification) => {
              notification.read = true;
              return notification;
            }),
          );

          setIsAllRead(true);
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        });
    }
  };

  useEffect(() => {
    const newNotificationsArray = [];
    const earlierNotificationsArray = [];

    notifications.forEach((not) => {
      if (not.read === false) {
        setIsAllRead(false);
        newNotificationsArray.push(not);
      } else {
        earlierNotificationsArray.push(not);
      }
    });

    setNewNotifications(newNotificationsArray);
    setEarlierNotifications(earlierNotificationsArray);
  }, [notifications]);

  const formatNotifications = (notifications) => {
    if (notifications.length === 0) {
      return (
        <div>
          <Row className="justify-content-center">
            <Col className="text-center">
              <p className="mb-3 mt-3 font-weight-normal text-500">
                No hay notificaciones
              </p>
            </Col>
          </Row>
        </div>
      );
    } else {
      return notifications.map((notification, index) => {
        const time = new Date(notification.createdAt.seconds * 1000);
        notification.time = time.toLocaleString("es-MX");

        return (
          <ListGroupItem key={index} onClick={handleToggle}>
            <Notification {...notification} flush />
          </ListGroupItem>
        );
      });
    }
  };

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={isOpen}
      toggle={handleToggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(false);
      }}
    >
      <DropdownToggle
        nav
        className={classNames("px-0", {
          "notification-indicator notification-indicator-primary": !isAllRead,
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <Card
          className="card-notification shadow-none"
          style={{ maxWidth: "20rem" }}
        >
          <FalconCardHeader
            className="card-header"
            title="Notificaciones"
            titleTag="h6"
            light={false}
          >
            <Link
              className="card-link font-weight-normal"
              to="#!"
              onClick={markAsRead}
            >
              Marcar como leídas
            </Link>
          </FalconCardHeader>

          <ListGroup flush className="font-weight-normal fs--1">
            <div className="list-group-title">NUEVAS</div>
            {formatNotifications(newNotifications)}
          </ListGroup>

          <ListGroup flush className="font-weight-normal fs--1">
            <div className="list-group-title">ANTIGUAS</div>
            {formatNotifications(earlierNotifications)}
          </ListGroup>

          <div
            className="card-footer text-center border-top-0"
            onClick={handleToggle}
          >
            {/*  <Link disabled className="card-link d-block" to="/pages/notifications">
              Ver todas
            </Link>*/}
          </div>
        </Card>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationDropdown;
