import React, { useContext } from "react";
import { ActivityContext } from "../../context/Context";
import CardSummary from "./CardSummary";

const CardNewClients = () => {
  const { newClients } = useContext(ActivityContext);

  return (
    <CardSummary
      title="Clientes Nuevos"
      color="info"
      linkText="Ver todo"
      to={"/marketing/customers/"}
    >
      {newClients}
    </CardSummary>
  );
};

export default CardNewClients;
