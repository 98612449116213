import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/illustrations/isotipo.png";
import AppContext from "../../context/Context";

const Logo = ({ at, cllname, width, className, ...rest }) => {
  const { isDark } = useContext(AppContext);
  return (
    <Link
      to="/"
      className={classNames(
        "text-decoration-none",
        { "navbar-brand text-left": at === "navbar-vertical" },
        { "navbar-brand text-left mr-0": at === "navbar-top" }
      )}
      {...rest}
    >
      <div
        className={classNames(
          "d-flex",
          {
            "align-items-center py-3": at === "navbar-vertical",
            "align-items-center": at === "navbar-top",
            "flex-center font-weight-extra-bold fs-5 py-3": at === "auth",
          },
          className
        )}
      >
        <img className="mr-2" src={logo} alt="Logo" width={width} />
        {/* <span className="text-sans-serif">Swirvle</span> */}
        <h2
          translate="no"
          className={
            `flex-center font-weight-bold mt-1 mb-1`
            // ${
            //   !isDark
            //     ? classNames(
            //         "",
            //         {
            //           "text-light": at === "navbar-top",
            //         },
            //         className
            //       )
            //     : ""
            // }
          }
          style={{ fontSize: "1.5rem", color: "#118df0" }}
        >
          swirvle
        </h2>
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(["navbar-vertical", "navbar-top", "auth"]),
  width: PropTypes.number,
  className: PropTypes.string,
};

Logo.defaultProps = { at: "auth", width: 58 };

export default Logo;
