import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactEchartsCore from "echarts-for-react/lib/core";
import "echarts/lib/chart/line";
import echarts from "echarts/lib/echarts";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";
import AppContext, { ActivityContext } from "../../context/Context";
import {
  getGrays,
  getPosition,
  numberFormatter,
  rgbaColor,
  themeColors,
} from "../../helpers/utils";
import FalconCardHeader from "../common/FalconCardHeader";
import Flex from "../common/Flex";

const getOption = (data, isDark) => {
  const grays = getGrays(isDark);
  return {
    tooltip: {
      triggerOn: "mousemove",
      trigger: "axis",
      padding: [7, 10],
      formatter: "{b0}: {c0}",
      backgroundColor: grays.white,
      borderColor: grays["300"],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark },
    },
    xAxis: {
      type: "category",
      data: ["Hace una Hora", "Esta Hora"],
      boundaryGap: false,
      splitLine: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          color: grays["300"],
          type: "dashed",
        },
      },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { type: "none" },
    },
    yAxis: {
      type: "value",
      splitLine: { show: false },
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { show: false },
    },
    series: [
      {
        type: "line",
        lineStyle: {
          color: themeColors.primary,
          width: 3,
        },
        itemStyle: {
          color: grays["100"],
          borderColor: themeColors.primary,
          borderWidth: 2,
        },
        hoverAnimation: true,
        data: data,
        connectNulls: true,
        smooth: 0.6,
        smoothMonotone: "x",
        symbol: "circle",
        symbolSize: 8,
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(themeColors.primary, 0.25),
              },
              {
                offset: 1,
                color: rgbaColor(themeColors.primary, 0),
              },
            ],
          },
        },
      },
    ],
    grid: { bottom: "2%", top: "0%", right: "10px", left: "10px" },
  };
};

const VisitsLastHour = () => {
  const { isDark } = useContext(AppContext);
  const { salesPastHour, salesTwoHoursAgo } = useContext(ActivityContext);

  let change = [salesTwoHoursAgo, salesPastHour];

  const total = change.reduce((total, currentValue) => total + currentValue, 0);

  function badge() {
    let rate;

    if (salesTwoHoursAgo !== 0) {
      rate = ((salesPastHour - salesTwoHoursAgo) / salesTwoHoursAgo) * 100;
      return (
        <Badge pill color={rate > 0 ? "soft-info" : "soft-warning"} className="fs--2">
          <FontAwesomeIcon icon={rate > 0 ? "caret-up" : "caret-down"} className="mr-1" />
          {rate.toFixed(2)}%
        </Badge>
      );
    } else {
      return <span />;
    }
  }

  return (
    <Card className="">
      <FalconCardHeader
        className="pb-0"
        title="Visitas en las Últimas Dos Horas"
        light={false}
        titleTag="h6"
      />
      <CardBody tag={Flex} align="end">
        <Row className="flex-grow-1">
          <Col className="align-self-end">
            <div className="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1">
              {numberFormatter(total, 0)}
            </div>
            {badge()}
          </Col>
          <Col xs="auto" className="pl-0">
            <ReactEchartsCore
              echarts={echarts}
              option={getOption(change, isDark)}
              style={{ width: "8.75rem", minHeight: "5rem", height: "100%" }}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

VisitsLastHour.propTypes = { data: PropTypes.array.isRequired };

export default VisitsLastHour;
