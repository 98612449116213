import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { default as UserDataContext } from "../../context/Context";
import FalconPlyr from "../common/FalconPlyr";

const WelcomeModal = ({ link, id, size }) => {
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const context = useContext(UserDataContext);

  size = size || "";
  let closeModalCounter = 0;

  const firestore = firebase.firestore();

  const updateModalView = () => {
    firestore
      .collection("posUsers")
      .doc(context.uid)
      .update({
        welcomeModalView: true,
      })
      .then(() => {
        console.log("Modal view updated");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    updateModalView();
    setOpen(false);
  };

  const handleHide = () => {
    setOpen(false);
  };

  //   const startTimer = () => {
  //     const appearTimer = setInterval(() => {
  //       closeModalCounter++;
  //       console.log(closeModalCounter);
  //       if (closeModalCounter > 60) {
  //         clearInterval(appearTimer);
  //         setDisabled(false);
  //         // setOpen(false);
  //       }
  //     }, 1000);
  //   };

  const getModalView = () => {
    firestore
      .collection("posUsers")
      .doc(context.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let modalView = doc.data().welcomeModalView;
          // console.log("Document data:", doc.data());
          if (
            modalView === undefined ||
            modalView === null ||
            modalView === false
          ) {
            setTimeout(() => {
              // console.log("Modal view:", modalView);
              setOpen(true);
              //   startTimer();
              // clearInterval(appearTimer);
            }, 1000);
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  useEffect(() => {
    getModalView();
    // console.log(context);
  }, []);

  return (
    <div>
      <Modal
        centered={true}
        isOpen={open}
        toggle={() => setOpen(!open)}
        size={size}
        backdrop={"static"}
        keyboard={false}
      >
        <ModalHeader>
          😃 ¡Bienvenid@ {context.name ? context.name : ""}! 🙌
        </ModalHeader>

        <ModalBody>
          <div className="rounded-soft overflow-hidden position-relative br-15">
            <FalconPlyr videoId={id} type="youtube" className="br-15" />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleHide()}>Ver en otro momento</Button>
          <Button onClick={() => handleClose()}>No volver a mostrar</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default WelcomeModal;
