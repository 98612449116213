import PropTypes from "prop-types";
import React from "react";
import Dot from "../common/Dot";
import Flex from "../common/Flex";

const CardClientRatioItem = ({ color, name, value, totalShare }) => {
  return (
    <Flex justify="between" align="center" className="mb-1">
      <Flex align="center">
        <Dot style={{ backgroundColor: color }} />
        <span className="font-weight-semi-bold">{name}</span>
      </Flex>
      <div className="">{value}</div>
    </Flex>
  );
};

CardClientRatioItem.propsType = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  totalShare: PropTypes.number.isRequired,
};

export default CardClientRatioItem;
