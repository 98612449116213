import React, { useContext } from "react";
import { ActivityContext } from "../../context/Context.js";
import CardSummary from "./CardSummary";

import { getPercentageChange } from "../../helpers/utils.js";

const CardDailyVisits = () => {
  const { timesUsedToday, timesUsedYesterday } = useContext(ActivityContext);

  return (
    <CardSummary
      title="Visitas"
      color={timesUsedToday < timesUsedYesterday ? "warning" : "success"}
      linkText="Ver todo"
      to="/statistics"
      rate={getPercentageChange(timesUsedYesterday, timesUsedToday, "string")}
    >
      {timesUsedToday}
    </CardSummary>
  );
};

export default CardDailyVisits;
