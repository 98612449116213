import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ButtonIcon from "../common/ButtonIcon";
import FalconPlyr from "../common/FalconPlyr";

const BtnTutorial = ({ link, id, title }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Modal centered={true} isOpen={open} toggle={() => setOpen(!open)}>
        <ModalHeader toggle={() => setOpen(!open)}>Tutorial</ModalHeader>

        <ModalBody>
          {title != undefined && <p className="w-100 text-center font-weight-bold">{title}</p>}

          <div className="rounded-soft overflow-hidden position-relative">
            <FalconPlyr videoId={id} type="youtube" />

            <br />
            <a href={link} target="_blank" style={{ fontFamily: "Poppins, Sans-serif" }}>
              Ver en Youtube
            </a>
          </div>
        </ModalBody>
        <ModalFooter>
          <Link to="/tutoriales" className="btn btn-outline-primary w-100">
            Ver más tutoriales
          </Link>
        </ModalFooter>
      </Modal>

      {title != undefined ? (
        <Button className="p-0" color="link" size="sm" onClick={() => setOpen(!open)}>
          {title}
        </Button>
      ) : (
        <ButtonIcon
          className="my-3 my-md-0 mr-1"
          icon="info-circle"
          transform="shrink-3 "
          color="outline-primary"
          size="sm"
          onClick={() => setOpen(!open)}
        >
          Tutorial
        </ButtonIcon>
      )}
    </div>
  );
};

export default BtnTutorial;
