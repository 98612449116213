import React, { useContext } from "react";
import { ActivityContext } from "../../context/Context.js";
import CardSummary from "./CardSummary";

const CardAverageAmount = () => {
  const { totalRegisteredSalesToday, timesUsedToday } = useContext(ActivityContext);

  const averageSales = () => {
    if (totalRegisteredSalesToday === 0 || timesUsedToday === 0) {
      return 0;
    }

    return Number((totalRegisteredSalesToday / timesUsedToday).toFixed(2));
  };

  return (
    <CardSummary
      content={averageSales()}
      title="Compra Promedio"
      to="/statistics"
      color={totalRegisteredSalesToday ? "success" : "warning"}
    >
      <span className="display-4 font-weight-normal fs-4 mb-2 text-sans-serif">
        ${averageSales()}
      </span>
    </CardSummary>
  );
};

export default CardAverageAmount;
