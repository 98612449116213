import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import React, { useContext, useEffect, useState } from "react";
import { ActivityContext, AppContext } from "../../context/Context.js";

const ActivityProvider = ({ children }) => {
  const { companyID } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [fullDataSet, setFullDataSet] = useState([]);
  const [employeeInfoArray, setEmployeeInfoArray] = useState([]);
  const [timesUsedToday, setTimesUsedToday] = useState(0);
  const [timesUsedYesterday, setTimesUsedYesterday] = useState(0);
  const [newClients, setNewClients] = useState(0);
  const [clients, setClients] = useState(0);
  const [totalRegisteredSalesToday, setTotalRegisteredSalesToday] = useState(0);
  const [averagePurchaseAmount, setAveragePurchaseAmount] = useState(0);
  const [totalRegisteredSalesYesterday, setTotalRegisteredSalesYesterday] =
    useState(0);
  const [salesPastHour, setSalesPastHour] = useState(0);
  const [salesTwoHoursAgo, setSalesTwoHoursAgo] = useState(0);
  const { locations, selectedLocation } = useContext(AppContext);

  const firestore = firebase.firestore();

  let usersID = [];

  let today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  let oneHourAgo = new Date();
  oneHourAgo.setHours(oneHourAgo.getHours() - 1);

  let twoHoursAgo = new Date();
  twoHoursAgo.setHours(twoHoursAgo.getHours() - 2);

  let yesterday = new Date(today);
  yesterday.setHours(-24);

  let tomorrow = new Date(today);
  tomorrow.setHours(24);

  let rewardsLogRoute = firestore
    .collection("companies")
    .doc(companyID)
    .collection("rewardsLog")
    .where("date", ">", yesterday)
    .where("date", "<", tomorrow);

  const clearState = () => {
    setData([]);
    setEmployeeInfoArray([]);
    setTimesUsedToday(0);
    setNewClients(0);
    setClients(0);
    setTimesUsedYesterday(0);
    setTotalRegisteredSalesToday(0);
    setAveragePurchaseAmount(0);
    setTotalRegisteredSalesYesterday(0);
    setSalesPastHour(0);
    setSalesTwoHoursAgo(0);
    usersID = [];
  };

  const filterData = () => {
    const newData = [];
    const locationIds = locations.map((e) => e.id);

    clearState();

    fullDataSet.forEach((doc) => {
      if (selectedLocation === "all") {
        if (
          locationIds.includes(doc.location) ||
          doc.locationId === null ||
          doc.location === null
        ) {
          const { employee, newClient, amount, client } = doc;

          let amountParsed = Number(parseFloat(amount).toFixed(2));
          let logDate = new Date(doc.date.seconds * 1000);
          let userID = client.uid;

          if (logDate > today) {
            setTimesUsedToday((pastValue) => pastValue + 1);
            setTotalRegisteredSalesToday(
              (pastValue) => pastValue + amountParsed,
            );

            setAveragePurchaseAmount(
              totalRegisteredSalesToday / timesUsedToday,
            );

            if (newClient) {
              setNewClients((pastValue) => pastValue + 1);
              usersID.push(userID);
            } else if (!usersID.includes(userID)) {
              setClients((pastValue) => pastValue + 1);
              usersID.push(userID);
            }

            if (logDate > oneHourAgo) {
              setSalesPastHour((pastValue) => pastValue + 1);
            }

            if (oneHourAgo > logDate && logDate > twoHoursAgo) {
              setSalesTwoHoursAgo((pastValue) => pastValue + 1);
            }

            newData.push({ ...doc, id: doc.id });
            employeeInfoArray.push({ ...employee });
          } else {
            setTimesUsedYesterday((pastValue) => pastValue + 1);
            setTotalRegisteredSalesYesterday(
              (pastValue) => pastValue + amountParsed,
            );
          }
        }
      } else {
        if (
          doc.locationId === selectedLocation ||
          doc.location === selectedLocation
        ) {
          const { employee, newClient, amount, client } = doc;

          let amountParsed = Number(parseFloat(amount).toFixed(2));
          let logDate1 = new Date(doc.date.seconds * 1000);
          let userID = client.uid;

          if (logDate1 > today) {
            setTimesUsedToday((pastValue) => pastValue + 1);
            setTotalRegisteredSalesToday(
              (pastValue) => pastValue + amountParsed,
            );

            setAveragePurchaseAmount(
              totalRegisteredSalesToday / timesUsedToday,
            );

            if (newClient && !usersID.includes(userID)) {
              setNewClients((pastValue) => pastValue + 1);
              usersID.push(userID);
            } else if (!usersID.includes(userID)) {
              setClients((pastValue) => pastValue + 1);
              usersID.push(userID);
            }

            if (logDate1 > oneHourAgo) {
              setSalesPastHour((pastValue) => pastValue + 1);
            }

            if (oneHourAgo > logDate1 && logDate1 > twoHoursAgo) {
              setSalesTwoHoursAgo((pastValue) => pastValue + 1);
            }

            newData.push({ ...doc, id: doc.id });
            employeeInfoArray.push({ ...employee });
          } else {
            setTimesUsedYesterday((pastValue) => pastValue + 1);
            setTotalRegisteredSalesYesterday(
              (pastValue) => pastValue + amountParsed,
            );
          }
        }
      }
    });

    setData(newData);

    setEmployeeInfoArray(
      employeeInfoArray
        .filter((object, index, self) => {
          if (object !== null) {
            return (
              self.findIndex((employee) => employee.uid === object.uid) ===
              index
            );
          }
        })
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),
    );
  };

  useEffect(() => {
    filterData();
  }, [selectedLocation, fullDataSet]);

  useEffect(() => {
    rewardsLogRoute
      .orderBy("date", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const { employee } = doc.data();

          data.push({ ...doc.data(), id: doc.id });
          employeeInfoArray.push({ ...employee });
        });
        //console.log("data", data);
        setFullDataSet(data);
      });
  }, []);

  useEffect(() => {
    const now = new Date();

    rewardsLogRoute.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach(function (change) {
        if (change.type === "added") {
          const { date } = change.doc.data();
          const { id } = change.doc;

          const timestamp = new Date(date.seconds * 1000);

          if (timestamp > now) {
            setFullDataSet((oldDataSet) =>
              [...oldDataSet, { ...change.doc.data(), id: id }].sort((a, b) => {
                return b.date.seconds - a.date.seconds;
              }),
            );
          }
        }
      });
    });
  }, []);

  let value = {
    fullDataSet: fullDataSet,
    data: data,
    setData: setData,
    newClients: newClients,
    clients: clients,
    totalRegisteredSalesYesterday: totalRegisteredSalesYesterday,
    totalRegisteredSalesToday: totalRegisteredSalesToday,
    averagePurchaseAmount: averagePurchaseAmount,
    salesPastHour: salesPastHour,
    salesTwoHoursAgo: salesTwoHoursAgo,
    timesUsedToday: timesUsedToday,
    timesUsedYesterday: timesUsedYesterday,
    employeeInfoArray: employeeInfoArray,
  };

  return (
    <ActivityContext.Provider value={value}>
      {children}
    </ActivityContext.Provider>
  );
};

export default ActivityProvider;
