import React, { useContext } from "react";
import CountUp from "react-countup";
import { ActivityContext } from "../../context/Context.js";
import CardSummary from "./CardSummary";

import { getPercentageChange } from "../../helpers/utils.js";

const CardDailyVisits = () => {
  const { totalRegisteredSalesToday, totalRegisteredSalesYesterday } = useContext(ActivityContext);

  return (
    <CardSummary
      content={totalRegisteredSalesToday}
      title="Ingresos"
      to="/statistics"
      color={totalRegisteredSalesToday < totalRegisteredSalesYesterday ? "warning" : "success"}
      rate={getPercentageChange(totalRegisteredSalesYesterday, totalRegisteredSalesToday, "string")}
    >
      <CountUp end={totalRegisteredSalesToday} duration={4} prefix="$" separator="," decimal="." />
    </CardSummary>
  );
};

export default CardDailyVisits;
