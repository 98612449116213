import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import Avatar from "../common/Avatar";

const ProfileDropdown = (props) => {
  const { name } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar name={name} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          {/*
          <DropdownItem className="font-weight-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="mr-1" />
            <span>Go Pro</span>
          </DropdownItem>
          <DropdownItem divider />*/}{" "}
          <DropdownItem>
            <strong>Conectado como: </strong>
            <br /> {name}
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="/auth/logout">
            Logout
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
