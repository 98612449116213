import loadable from "@loadable/component";
import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import PaymentsLineChart from "./PaymentsLineChart";
// import Customers from './Customers';
import ActivityProvider from "./ActivityProvider";
import AutomationBudgetIndicator from "./AutomationBudgetIndicator";
import CardAverageAmount from "./CardAverageAmount";
import CardClientRatio from "./CardClientRatio";
import CardDailyIncome from "./CardDailyIncome";
import CardDailyVisits from "./CardDailyVisits";
import CardNewClients from "./CardNewClients";
import ClaimsLog from "./ClaimsLog";
import Orders from "./Orders";
import VisitsLastHour from "./VisitsLastHour";
import WelcomeModal from "./WelcomeModal";
import BlogAd from "./blogAd";

const PurchasesTable = loadable(() => import("./PurchasesTable"));
const ActiveUsersMap = loadable(() => import("./ActiveUsersMap"));

const Dashboard = () => {
  return (
    <ActivityProvider>
      <Fragment>
        <PaymentsLineChart />
        <Row
          noGutters
          className="d-flex align-items-center justify-content-center"
          style={{
            maxWidth: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col
            xs={6}
            md={6}
            lg={3}
            className="px-1"
            style={{ display: "flex", alignItems: "stretch" }}
          >
            {/* <div className="card-deck"> */}
            <CardNewClients />
          </Col>
          <Col
            xs={6}
            md={6}
            lg={3}
            className="px-1"
            style={{
              display: "flex",
              alignItems: "stretch",
            }}
          >
            <CardDailyVisits />
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="px-1"
            style={{ display: "flex", alignItems: "stretch" }}
          >
            <CardDailyIncome />
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="px-1"
            style={{
              display: "flex",
              alignItems: "stretch",
            }}
          >
            <CardAverageAmount />

            {/* </div> */}
          </Col>
        </Row>

        <Row noGutters>
          <Col
            md={4}
            lg={4}
            xl={4}
            className="col-xxl-4 pl-lg-0 pl-xl-0 pl-xxl-0 pr-xl-2 pr-xxl-2 mb-3"
          >
            <CardClientRatio />
            {/* <br /> */}
            <div className="my-2"></div>
            <VisitsLastHour />
          </Col>
          <Col
            md={4}
            lg={4}
            xl={4}
            className="col-xxl-4 pl-lg-0 pl-xl-0 pl-xxl-2 pr-xl-2 pr-xxl-2 mb-3"
          >
            <AutomationBudgetIndicator />
          </Col>

          <Col
            md={4}
            lg={4}
            xl={4}
            className="col-xxl-4 pl-lg-0 pl-xl-0 pl-xxl-2 pr-xl-2 pr-xxl-0 mb-3"
          >
            <BlogAd />
          </Col>
        </Row>

        {/* <Row noGutters>
          <Col
            md={4}
            lg={4}
            xl={4}
            className="col-xxl-4 pl-lg-0 pl-xl-0 pl-xxl-2 pr-xl-2 pr-xxl-2 mb-3"
            >
            <BlogAd />
          </Col>

          <Col
            md={6}
            lg={6}
            xl={6}
            className="col-xxl-4 pl-lg-0 pl-xl-0 pl-xxl-2 pr-xl-2 pr-xxl-2 mb-3"
            >
            <CardStoreRatio />
            <BarChart />
          </Col> 
        </Row> */}
        <Orders />

        {/*
      <DashBoardDepositStatus />
      <Row>
        <Col lg={6}>
          <ChartExample />
        </Col>
        <Col>
          <div className="card-deck">
            <CardSummary
              rate="0.23%"
              title="Cantidad de ventas"
              color="success"
              linkText="Ver más"
            >
              395
            </CardSummary>
            <CardSummary
              rate="0.0%"
              title="Facturas expedidas"
              color="info"
              linkText="Ver más"
            >
              43
            </CardSummary>
            <CardSummary
              rate="9.54%"
              title="Compra promedio"
              color="success"
              linkText="Ver más"
            >
              <CountUp
                end={43}
                duration={5}
                prefix="$"
                separator=","
                decimal="."
              />
            </CardSummary>
          </div>
        </Col>
      </Row>
      <br />*/}
        {/* <Customers /> */}
        <ClaimsLog />
        {/*
      <Row>
        <Col lg={7} className="mb-3">
          <BestSellingProducts className="h-lg-100" products={products} />
        </Col>
        <Col lg={5} className=" mb-3">
          <ActiveUsersBarChart />
        </Col>
      </Row>

       <Row noGutters>
        <Col className="col-xxl-6 px-xxl-2 mb-3">
          <TopProducts data={topProducts} colors={productColors} className="h-100" />
        </Col>
      </Row> */}

        {/*<Row noGutters>
        
        <Col className="col-xxl-12 px-xxl-2 mb-3">
          <VentasClientes
            data={topProducts}
            colors={productColors}
            className="h-100"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={7} className="mb-3 pl-lg-2 mb-3">
          <TotalSales className="h-lg-100" />
        </Col>
        */}

        <WelcomeModal id={"lGXws9_i1PQ"} size={"lg"} />
      </Fragment>
    </ActivityProvider>
  );
};

export default Dashboard;
