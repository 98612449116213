export const pagoRoutes = {
  name: "Pagos",
  to: "/pagos",
  icon: "file-invoice-dollar",
  permissionLevelRequired: 3,
  children: [
    {
      to: "/pagos/formas-de-pago",
      name: "Formas de Pago",
      permissionLevelRequired: 3
    },
    {
      to: "/pagos/facturacion",
      name: "Datos de Facturación",
      permissionLevelRequired: 3
    }
  ]
};

export default [pagoRoutes];
