import React from "react";

const PortalSession = () => {
  return (
    <div>
      <h1>PortalSession</h1>
    </div>
  );
};

export default PortalSession;
