import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CloseButton, Fade } from "../components/common/Toast";

import DashboardLayout from "./DashboardLayout";
import ErrorLayout from "./ErrorLayout";

import loadable from "@loadable/component";
import { Subject } from "rxjs";

import PortalSession from "../components/portalSession/portalSession";

const subject = new Subject();

export const dataService = {
  setData: (d) => subject.next({ value: d }),
  clearData: () => subject.next(),
  getData: () => subject.asObservable(),
};

const AuthBasicLayout = loadable(() => import("./AuthBasicLayout"));
const EmployeeLayout = loadable(() => import("./EmployeeLayout"));
const LoginLayout = loadable(() => import("./LoginLayout"));
const LandingLayout = loadable(() => import("../components/landing2/LandingLayout"));
const PrivacyPolicy = loadable(() => import("../components/privacy/PrivacyPolicy"));
const DemoLayout = loadable(() => import("../components/demo/DemoLayout"));
// const LandingLayout = loadable(() => import("../components/landing/Landing"));
const BlogLayout = loadable(() => import("../components/blog/blogLayout"));
const BlogDetail = loadable(() => import("../components/blog/blogDetail"));

// Landing Components
const FaqP = loadable(() => import("../components/landing/faqPlanes"));
const AccionCov = loadable(() => import("../components/landing/reactiv"));
const Sob = loadable(() => import("../components/landing/sobre"));
const Registro = loadable(() => import("../components/landing/registro"));
const BillP = loadable(() => import("../components/landing/billingPlans"));

// Auth Components
const WizardLayout = loadable(() => import("../components/auth/wizard/WizardLayout"));
const AuthCardRoutes = loadable(() => import("../components/auth/card/AuthCardRoutes"));
const AuthSplitRoutes = loadable(() => import("../components/auth/split/AuthSplitRoutes"));

const Layout = () => {
  useEffect(() => {
    AuthBasicLayout.preload();
    EmployeeLayout.preload();
    AccionCov.preload();
    BlogLayout.preload();
    BlogDetail.preload();
    DemoLayout.preload();
    LandingLayout.preload();
    Sob.preload();
    FaqP.preload();
    Registro.preload();
    BillP.preload();
    WizardLayout.preload();
    AuthCardRoutes.preload();
    AuthSplitRoutes.preload();
    LoginLayout.preload();
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/landing" exact component={LandingLayout} />
        <Route path="/privacy" exact component={PrivacyPolicy} />
        <Route path="/demosuccess" exact component={DemoLayout} />
        {/* <Route path="/faqplanes" exact component={FaqP} /> */}
        {/* <Route path="/reactiv" exact component={AccionCov} /> */}
        {/* <Route path="/sobrenos" exact component={Sob} /> */}
        {/* <Route path="/billing" exact component={BillP} /> */}
        <Route path="/auth" component={AuthBasicLayout} />
        <Route path="/e" component={EmployeeLayout} />
        <Route path="/blog" exact component={BlogLayout} />
        <Route path="/blog-post" component={BlogDetail} />
        <Route path="/authentication/card" component={AuthCardRoutes} />
        <Route path="/authentication/split" component={AuthSplitRoutes} />
        <Route path="/authentication/wizard" component={WizardLayout} />
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/login" exact component={LoginLayout} />
        <Route path="/register" component={Registro} />
        <Route path="/register/ref/:id" component={Registro} />

        <Route path="/portalSession" component={PortalSession} />
        <Route path="/" component={DashboardLayout} />
      </Switch>
      <ToastContainer
        transition={Fade}
        closeButton={<CloseButton />}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </Router>
  );
};

export default Layout;
