import firebase from "firebase/compat/app";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Carousel, CarouselItem } from "reactstrap";
import corner2 from "../../assets/img/illustrations/corner-2.png";
import RawBlogs from "../../data/blog/blog";
import Background from "../common/Background";
const BlogAd = ({ className }) => {
  const { loading, data } = RawBlogs();
  const [reciente, setReciente] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  var imagePath;

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === reciente.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? reciente.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  function getDate(inputt) {
    var calendar = new Date(inputt.seconds * 1000);
    var months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    var dd =
      calendar.getDate() +
      "/" +
      months[calendar.getMonth()] +
      "/" +
      calendar.getFullYear();
    return dd;
  }

  const shortTitle = (title) => {
    if (title.length > 60) {
      return title.substring(0, 60) + "...";
    } else {
      return title;
    }
  };

  useEffect(() => {
    var t;
    var recent = [];
    for (t = 0; t < data.length; t++) {
      if (t < 5) {
        recent.push(data[t]);
      }
      if (t > 5) {
        break;
      }
    }
    setReciente(recent);
  }, [loading]);

  return (
    <Card
      className={`overflow-hidden ${className} `}
      style={{ minHeight: "10.5rem", height: "100%" }}
    >
      <Background image={corner2} className="p-card bg-card" />
      <CardBody className="position-relative">
        <h6 className="">Reciente en blog</h6>

        <div className="h-100">
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            variant="dark"
            pause={false}
            ride="carousel"
          >
            {/* <CarouselIndicators
              items={reciente}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            /> */}
            {reciente.map((rec, index) => (
              <CarouselItem
                interval={1000}
                key={index}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
              >
                <Link className="text-600" to={`/blog-post/${rec.id}`}>
                  <img
                    top
                    src={rec.imgUrl}
                    alt="Card image"
                    id="imgBanner"
                    className="w-100 br-15"
                    style={{ objectFit: "cover", height: "9rem" }}
                  />

                  <h5 className="mt-2 mb-0">{shortTitle(rec.title)}</h5>
                </Link>
                <small className="text-600 opacity-75">
                  {getDate(rec.Timestamp)}
                  {/* &bull;{blog.read} read{" "}
                {blog.star && (
                  <span dangerouslySetInnerHTML={createMarkup("&starf;")} />
                )} */}
                </small>
              </CarouselItem>
            ))}

            {/* <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            /> */}
          </Carousel>
        </div>
      </CardBody>
    </Card>
  );
};

BlogAd.propTypes = {
  className: PropTypes.string,
};

export default BlogAd;
