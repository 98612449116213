import { createContext } from "react";

export const AppContext = createContext({
  isFluid: false,
  isRTL: false,
  isDark: false,
  setIsDark: () => {},
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: "$",
  isNavbarVerticalCollapsed: false,
  navbarStyle: "vibrant",
  userData: {},
});

export const EmailContext = createContext({ emails: [] });

export const ProductContext = createContext({ products: [] });

export const ActivityContext = createContext({ activity: [] });

export const SucursalContext = createContext({ sucursales: [] });

export const UserDataContext = createContext({ companyid: "", userid: "" });

export const FeedContext = createContext({ feeds: [] });

export const AuthWizardContext = createContext({ user: {} });

export const ChatContext = createContext();

export const KanbanContext = createContext({
  KanbanColumns: [],
  kanbanTasks: [],
});

export default AppContext;
