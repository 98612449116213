import React, { useContext } from "react";
import { Form, Input } from "reactstrap";
import AppContext from "../../context/Context.js";

const LocationSelect = () => {
  const { locations, selectedLocation, setSelectedLocation, franchiseId, franchises } = useContext(
    AppContext
  );

  // let filteredLocations = []

  // if(!franchiseId) {
  //   filteredLocations = locations
  // } else {
  //   const franchise = franchises.find(franch => franch.id === franchiseId)
  //   let franchiseLocationsArray = []
  //   franchise.locations.forEach(franch => {
  //     franchiseLocationsArray.push(locations.find(loc => franch === loc.id))
  //   });
  //   filteredLocations = franchiseLocationsArray;
  // }

  return (
    <Form inline className="">
      <Input
        type="select"
        aria-label="Search"
        className=""
        onChange={(e) => {
          setSelectedLocation(e.target.value);
        }}
      >
        <option value="all">Todas las sucursales</option>
        {locations.map((location, i) => {
          return (
            <option value={location.id} key={location.id}>
              {location.name}
            </option>
          );
        })}
      </Input>
    </Form>
  );
};

export default LocationSelect;
