import React, { useContext, useState } from "react";
import { Line } from "react-chartjs-2";
import CountUp from "react-countup";
import { Card, CardBody, Col, Row } from "reactstrap";
import AppContext, { ActivityContext } from "../../context/Context";
import { rgbaColor, themeColors } from "../../helpers/utils";

const PaymentsLineChart = () => {
  const [paymentStatus, setPaymentStatus] = useState("successful");
  const { isDark } = useContext(AppContext);
  const { data, totalRegisteredSalesToday, totalRegisteredSalesYesterday } = useContext(
    ActivityContext
  );

  const config = {
    data(canvas) {
      const ctx = canvas.getContext("2d");
      const gradientFill = isDark
        ? ctx.createLinearGradient(0, 0, 0, ctx.canvas.height)
        : ctx.createLinearGradient(0, 0, 0, 250);
      gradientFill.addColorStop(0, isDark ? "rgba(44,123,229, 0.5)" : "rgba(255, 255, 255, 0.3)");
      gradientFill.addColorStop(1, isDark ? "transparent" : "rgba(255, 255, 255, 0)");

      return {
        labels: data.map((log) => log.date.seconds * 1000),
        datasets: [
          {
            borderWidth: 2,
            data: data.map((log) => (log.amount * 1).toFixed(2)),
            borderColor: rgbaColor(isDark ? themeColors.primary : "#fff", 0.8),
            backgroundColor: gradientFill,
          },
        ],
      };
    },
    options: {
      legend: { display: false },
      tooltips: {
        mode: "x-axis",
        xPadding: 20,
        yPadding: 10,
        displayColors: false,
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.label} - ${tooltipItem.yLabel} MXN`,
          title: () => null,
        },
      },
      hover: { mode: "label" },
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              unit: "hour",
            },
            scaleLabel: {
              show: true,
              labelString: "Month",
            },
            ticks: {
              fontColor: rgbaColor("#fff", 0.7),
              fontStyle: 600,
            },
            gridLines: {
              color: rgbaColor("#fff", 0.1),
              zeroLineColor: rgbaColor("#fff", 0.1),
              lineWidth: 1,
            },
          },
        ],
        yAxes: [
          {
            display: false,
            gridLines: {
              color: rgbaColor("#fff", 1),
            },
          },
        ],
      },
    },
  };

  return (
    <Card className="mb-3">
      <CardBody className="rounded-soft bg-gradient">
        <Row className="text-white align-items-center no-gutters">
          <Col>
            <h4 className="text-white mb-0">
              Hoy{" "}
              <CountUp
                end={totalRegisteredSalesToday}
                duration={4}
                prefix="$"
                separator=","
                decimal="."
              />
            </h4>
            <p className="fs--1 font-weight-semi-bold">
              Ayer{" "}
              <span className="opacity-50">
                <CountUp
                  end={totalRegisteredSalesYesterday}
                  duration={2}
                  prefix="$"
                  separator=","
                  decimal="."
                />
              </span>
            </p>
          </Col>
          {/* 
          <Col xs="auto" className="d-none d-sm-block">
            <CustomInput
              id="ddd"
              type="sct"
              bsSize="sm"
              className="mb-3 shadow"
              value={paymentStatus}
              onChange={({ target }) => setPaymentStatus(target.value)}
            >
              <option value="all">All Payments</option>
              <option value="successful">Successful Payments</option>
              <option value="failed">Failed Payments</option>
            </CustomInput>
          </Col>*/}
        </Row>
        <Line data={config.data} options={config.options} width={1618} height={375} />
      </CardBody>
    </Card>
  );
};

export default PaymentsLineChart;
