import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import "react-datetime/css/react-datetime.css";
import "react-image-lightbox/style.css";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import Layout from "./layouts/Layout";
import { useJsApiLoader } from "@react-google-maps/api";

// const stripePromise = loadStripe('pk_test_51JYQeSAFLfCBNH1Dt0pLHfvhx3rgKPtx2KOCt2K93jCvvuW6cixYzWwqZE0NQ5jQ2LZqSunOjob1lfOjKxqnlWyt00O7YHpOaN'); // TEST
const stripePromise = loadStripe(
  "pk_live_51JcIp1C8fbG4vJdTxOfLAtozSu2KBCnBVe94Y4VzHqtD1pB10AFiLKE04JYgeDuYn5lCTkSk0vym1HKIz9JTDPpg00Lo4eRV7y"
); // LIVE

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <Router basename={process.env.PUBLIC_URL}>
        <Layout />
      </Router>
    </Elements>
  );
};

export default App;
