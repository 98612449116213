import firebase from "firebase/compat/app";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import { auth } from "../../index";
import { signOut } from "firebase/auth";

const Error512 = () => {
  let history = useHistory();

  const logout = () => {
    signOut(auth).then(() => {
      history.push("/auth/logout");
    });
  };

  useEffect(() => {
    auth.onAuthStateChanged((auth) => {
      if (!auth) {
        history.push("/");
      }
    });
  }, []);

  return (
    <Card className="text-center h-100">
      <CardBody className="p-5">
        <p className="lead text-800 text-sans-serif font-weight-semi-bold">
          Parece que no eres miembro de un negocio
        </p>
        <p>
          Por el momento la única manera de ser miembro de un negocio es a
          través de invitación o poniendote en contacto con nosotros:
          <a href="mailto:hola@swirvle.com" className="ml-1">
            contáctanos
          </a>
          .
        </p>
        <Button
          color={"primary"}
          block
          className="mt-4"
          onClick={() => {
            logout();
          }}
        >
          Cerrar Sesión
        </Button>
      </CardBody>
    </Card>
  );
};

export default Error512;
