import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faMessageQuestion, faXmark } from "@fortawesome/pro-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Collapse, Spinner } from "reactstrap";
import { AppContext } from "../../context/Context";
import FalconPlyr from "../common/FalconPlyr";
import BtnTutorial from "../help/BtnTutorial";

const CustomerSupportWhatsapp = () => {
  const { companyID, companyData, franchiseId } = useContext(AppContext);
  const cardBodyRef = useRef(null);

  const [userName, setUserName] = useState("");
  const [userID, setUserID] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [currentRoute, setCurrentRoute] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);

  const [chatMessages, setChatMessages] = useState([
    {
      message: "Hola, ¿en qué puedo ayudarte hoy?",
      from: "agent",
    },
  ]);
  const [message, setMessage] = useState("");

  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const getYoutubeId = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  // useEffect(() => {
  //   if (cardBodyRef.current) {
  //     cardBodyRef.current.scrollTop = cardBodyRef.current.scrollHeight;
  //   }
  // }, [chatMessages]);

  useEffect(() => {
    if (cardBodyRef.current) {
      cardBodyRef.current.scrollTop = cardBodyRef.current.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    let posUserInfo = {};
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        return firestore
          .collection("posUsers")
          .doc(authUser.uid)
          .get()
          .then((doc) => {
            posUserInfo = doc.data();
            setUserName(posUserInfo.name);
            setUserID(posUserInfo.uid);
          });
      }
    });
  }, []);

  const handleSendMessage = async () => {
    if (!message.trim()) return;
    // Clear the user input
    setMessage("");
    setLoadingChat(true);

    // Add user's message to chat
    setChatMessages((prevMessages) => [...prevMessages, { message: message, from: "user" }]);

    try {
      const processUserMessage = firebase.functions().httpsCallable("processUserMessageSupportAi");
      const response = await processUserMessage({ message: message, plan: companyData?.plan });

      console.log(response);
      // Add assistant's reply to chat
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { message: response.data, from: "agent" },
      ]);
      setLoadingChat(false);
    } catch (error) {
      setLoadingChat(false);
      console.error("Error sending message:", error);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { message: "There was an error processing your message.", from: "agent" },
      ]);
    }
  };

  const routes = [
    {
      route: "/",
      tutorials: [
        { link: "https://www.youtube.com/watch?v=lGXws9_i1PQ", title: "Bienvenid@ ¿Cómo empezar?" },
      ],
      csVideo: "https://youtu.be/oxi2WgaMNMY",
      title: "Inicio",
      description:
        "Ésta es tu página de inicio. Aquí podrás ver un resumen en vívo del día de hoy de tu negocio.",
    },
    {
      route: "/statistics",
      // tutorials: [{ link: "", title: "" }],
      tutorials: [],
      csVideo: "https://www.youtube.com/watch?v=jlwLxhaJwX0",
      title: "Estadísticas",
      description:
        "Aquí podrás ver las estadísticas históricas a detalle de tu negocio. Podrás encontrar indicadores de retención, ventas, visitas, clientes VIP, así como clientes inactivos y más.",
    },
    {
      route: "/visits",
      tutorials: [],
      csVideo: "https://youtu.be/zMFvnYSyeC0",
      title: "Visitas recientes",
      description:
        "Aquí podrás ver las visitas recientes a tu negocio, así como la información de los clientes que han visitado tu negocio. Puedes editar o borrar en caso de que te haya equivocado.",
    },
    {
      route: "/marketing/customers",
      tutorials: [],
      csVideo: "https://www.youtube.com/watch?v=wEzWenxzwdc",
      title: "Clientes",
      description:
        "Aquí podrás ver a tus clientes, así como la información e historial de compras y canjes de cada uno de ellos. Puedes editar su cantidad de puntos o borrar clientes.",
    },
    {
      route: "/marketing/campaigns",
      tutorials: [
        {
          link: "https://www.youtube.com/watch?v=xJ3HMxjeycY",
          title: "¿Cómo hacer campañas de marketing?",
        },
      ],
      csVideo: "https://www.youtube.com/watch?v=STwn2Kggcrc",
      title: "Campañas",
      description:
        "Aquí podrás crear campañas de marketing para tus clientes. Podrás crear y ver los resultados de campañas de email, email avanzado, notificaciones push y más.",
    },

    {
      route: "/marketing/campaigns/add",
      tutorials: [
        {
          link: "https://www.youtube.com/watch?v=xJ3HMxjeycY",
          title: "¿Cómo hacer campañas de marketing?",
        },
      ],
      csVideo: "https://www.youtube.com/watch?v=STwn2Kggcrc",
      title: "Campañas",
      description:
        "Aquí podrás crear campañas de marketing para tus clientes. Podrás crear y ver los resultados de campañas de email, email avanzado, notificaciones push y más.",
    },
    {
      route: "/lealtad/visitas",
      tutorials: [
        {
          link: "https://www.youtube.com/watch?v=dgJc6usHsQI",
          title: "¿Cómo crear una promoción por visitas?",
        },
      ],
      csVideo: "https://youtu.be/9Jc7NWEvWuI",
      title: "Promociones por visitas",
      description:
        "Aquí podrás crear promociones por visitas a tu negocio. Una promoción por visitas es una promoción que se activa cuando un cliente haya acumulado cierta cantidad de visitas con compra mínima en tu negocio.",
    },
    {
      route: "/lealtad/productos",
      tutorials: [
        {
          link: "https://www.youtube.com/watch?v=vJJJESXn8M8",
          title: "¿Cómo crear una promoción por compra de producto?",
        },
      ],
      csVideo: "https://youtu.be/XPIuyxUTNrE",
      title: "Promociones por productos",
      description:
        "Aquí podrás crear promociones por productos. Una promoción por productos es una promoción que se activa cuando un cliente haya acumulado cierta cantidad de un producto específico comprados en tu negocio.",
    },
    {
      route: "/lealtad/puntos",
      tutorials: [
        {
          link: "https://www.youtube.com/watch?v=40_bD5fxaL0",
          title: "¿Cómo crear una promoción por puntos?",
        },
      ],
      csVideo: "https://youtu.be/Np36-low0xQ",
      title: "Promociones por puntos",
      description:
        "Aquí podrás crear promociones por puntos. Una promoción por puntos es una promoción que se activa cuando un cliente haya acumulado cierta cantidad de puntos en tu negocio. Los puntos se acumulan por cada compra que realice el cliente y tu configuras cuantos puntos otorgar por cada peso.",
    },
    {
      route: "/lealtad/settings",
      tutorials: [],
      csVideo: "https://youtu.be/X-xSN2wzF8I",
      title: "Configuración de promociones",
      description: "Aquí podrás personalizar un poco más la dinámica de tu programa de lealtad.",
    },
    {
      route: "/promotions/coupons",
      tutorials: [
        {
          link: "https://www.youtube.com/watch?v=yYBo2Aubb5Q",
          title: "¿Cómo crear un cupón inteligente?",
        },
      ],
      csVideo: "https://www.youtube.com/watch?v=UAqF7tMGSqc",
      title: "Cupones",
      description:
        "Los cupones inteligentes son un beneficio puntual (ya activo) para un cliente o grupo de clientes en específico. Tu segmentas a tus clientes y decides a quién le quieres dar el beneficio.",
    },
    {
      route: "/promotions/birthday",
      tutorials: [],
      csVideo: "https://youtu.be/-9NjurbXun0",
      title: "Cupón de cumpleaños",
      description:
        "En cupón de cumpleaños es un beneficio que se activa automáticamente durante el mes de cumpleaños de tus clientes. Además éste beneficio estará disponible no solo para tus clientes, sino también todos los usuarios de Swirvle cerca de tu negocio, para que así puedas atraer más clientes.",
    },
    {
      route: "/inventory/products",
      tutorials: [
        { link: "https://youtu.be/vJJJESXn8M8?t=25", title: "¿Cómo agregar un producto?" },
      ],
      csVideo: "",
      title: "Productos",
      description:
        "Aquí podrás ver tus productos y servicios. Podrás agregar, editar o borrar productos y servicios, los cuales serán usados en las promociones por producto (si es que tienes promociones por producto).",
    },
    {
      route: "/survey",
      tutorials: [
        {
          link: "https://www.youtube.com/watch?v=nxWutuSUu6o",
          title: "¿Cómo agregar una encuesta?",
        },
      ],
      csVideo: "",
      title: "Encuestas",
      description:
        "Aquí podrás configurar encuestas para tus clientes. Éstas encuestas se envían en automático después de cada compra o cuando es un cliente nuevo.",
    },
    {
      route: "/automations/all",
      tutorials: [],
      csVideo: "https://youtu.be/wVBO9AVk06c",
      title: "Automatizaciones",
      description:
        "Las automatizaciones son sistemas diseñados para ejecutar tareas repetitivas y procesos de gestión de relaciones con clientes de manera automática sin intervención humana. Envía notificaciones, correos, cupones para clientes, campañas de marketing, asignación de puntos a clientes y la eliminación de clientes. Algunos ejemplos de flujos son reactivar clientes perdidos, aumentar el consumo con promociones dirigidas, y gestionar campañas de hora feliz.",
    },
    {
      route: "/automations/log",
      tutorials: [],
      csVideo: "",
      title: "Historial de automatizaciones",
      description:
        "Aquí podrás ver el historial de todas las automatizaciones que se han ejecutado. Podrás observar las ejecucciones exitosas y fallidas.",
    },
    {
      route: "/scan",
      tutorials: [
        { link: "https://youtu.be/oWqEvv-WRRk", title: "¿Cómo registrar una compra?" },
        { link: "https://youtu.be/ECVbz_fxBXM", title: "¿Cómo canjear una promoción?" },
      ],
      csVideo: "https://youtu.be/iSGFdSADh3A",
      title: "Escanear",
      description:
        "Aquí podrás escanear códigos QR de clientes para agregar puntos o canjear promociones con un escanner de 2D.",
    },
    {
      route: "e/scan",
      tutorials: [
        { link: "https://youtu.be/oWqEvv-WRRk", title: "¿Cómo registrar una compra?" },
        { link: "https://youtu.be/ECVbz_fxBXM", title: "¿Cómo canjear una promoción?" },
      ],
      csVideo: "https://youtu.be/iSGFdSADh3A",
      title: "Escanear",
      description:
        "Aquí podrás escanear códigos QR de clientes para agregar puntos o canjear promociones con un escanner de 2D.",
    },
    {
      route: "/storefront",
      tutorials: [
        { link: "https://youtu.be/Mrx37udwRI0", title: "¿Cómo escanear una compra por consumo?" },
        { link: "https://youtu.be/zlanEhWrM-0", title: "¿Cómo escanear una compra por producto?" },
        { link: "https://youtu.be/7fY-jn9FGvQ", title: "¿Cómo canjear una recompensa?" },
      ],
      csVideo: "https://youtu.be/iSGFdSADh3A",
      title: "App para sucursales",
      description:
        "Aquí podrás ver la app para sucursales. Ésta app sirve para escanear los códigos QR de clientes para agregar puntos o canjear promociones.",
    },
    {
      route: "/store",
      tutorials: [
        { link: "https://www.youtube.com/watch?v=WhbqAvJUeIM", title: "¿Cómo agregar sucursales?" },
      ],
      csVideo: "https://youtu.be/Hkrquz8PWFM",
      title: "Mi negocio",
      description:
        "Aquí podrás ver la información general de tu negocio, así como el logotipo, foto de portada, información de contacto y redes sociales.",
    },
    {
      route: "/share-section",
      tutorials: [],
      csVideo: "https://youtu.be/RdbGmVEf6V4",
      title: "Recursos",
      description:
        "Aquí podrás ver los recursos que Swirvle ha creado para ti y para que puedas darle difusión a tu programa de lealtad. Encuentra el enláce de tu negocio, códigos QR, imágenes y más.",
    },
    {
      route: "/store/team",
      tutorials: [
        { link: "https://www.youtube.com/watch?v=xZdIKB8rFrY", title: "¿Cómo agregar miembros?" },
      ],
      csVideo: "https://youtu.be/mAmk8s1UN-M",
      title: "Mi equipo",
      description:
        "Aquí podrás ver a tu equipo de trabajo. Podrás agregar, editar o borrar a tus empleados. Además podrás asignarles permisos específicos para que puedan acceder a ciertas secciones de la plataforma.",
    },
    {
      route: "/pagos/facturación",
      tutorials: [],
      csVideo: "",
      title: "Facturación",
      description:
        "Lléna aquí tus datos fiscales para pedir factura de tu subscripción de Swirvle.",
    },
    {
      route: "/pages/associations",
      tutorials: [],
      csVideo: "",
      title: "Convenios",
      description:
        "Aquí podrás ver los convenios que Swirvle tiene con otras empresas en beneficio de tu negocio. Podrás encontrar precios especiales, promociones y más en productos y servicios para tu negocio.",
    },
  ];

  useEffect(() => {
    setLoading(true);
    const path = window.location.pathname;
    const foundRoute = routes.find((route) => route.route === path);
    if (foundRoute) {
      setCurrentRoute(foundRoute);
      console.log("currentRoute", foundRoute);
      setTimeout(() => {
        setLoading(false);
      }, 400);
    } else {
      setCurrentRoute({});
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [window.location.pathname]);

  return (
    <div
      style={{
        position: "fixed",
        bottom: "1.9rem",
        right: "1rem",
        zIndex: "1000",
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        justifyContent: "end",
      }}
    >
      <Collapse isOpen={isOpen}>
        <Card className="mb-4 card-help">
          <CardHeader className="border-bottom pb-1 mb-2 d-flex justify-content-between align-items-baseline">
            <h5 className="title">Centro de Ayuda</h5>

            <Button
              color="link"
              className="p-0"
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(!isOpen);
              }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                style={{
                  color: "black",
                }}
              />
            </Button>
          </CardHeader>

          {currentRoute?.csVideo && (
            <div>
              {/* <div className="px-2">
                <small>
                  Relevante para <b>{currentRoute.title}</b>
                </small>
              </div> */}
              {loading ? (
                <div className="p-7 d-flex align-items-center justify-content-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                <FalconPlyr videoId={getYoutubeId(currentRoute.csVideo)} type="youtube" />
              )}
            </div>
          )}

          <CardBody>
            {currentRoute?.description ? (
              <p className="fs--1">{currentRoute.description}</p>
            ) : (
              <p className="fs--1">
                Contáctanos por whatsapp si tienes alguna duda. Nuestro equipo te atenderá lo más
                pronto posible.
              </p>
            )}

            {currentRoute?.tutorials?.length >= 1 && (
              <div>
                <h6>Tutoriales</h6>
                {currentRoute.tutorials.map((tutorial, index) => {
                  return (
                    <div>
                      <BtnTutorial
                        link={tutorial.link}
                        id={getYoutubeId(tutorial.link)}
                        title={tutorial.title}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </CardBody>

          <CardFooter className="d-flex align-items-center justify-content-between p-0">
            <a
              style={{
                color: "white",
                padding: "0.3rem",
                backgroundColor: "#25D366",
                textDecoration: "none",
                borderBottomRightRadius: "5px",
                borderBottomLeftRadius: "5px",
              }}
              className="w-100 text-center"
              target="__blank"
              href={`https://wa.me/528128826598?text=*No%20borrar%20esta%20informaci%C3%B3n*%0A${companyData?.companyName}%0AcID:%20${companyID}%0Acliente:%20${userName}%0AID:%20${userID}%0A*No%20borrar%20esta%20informaci%C3%B3n*%0A`}
            >
              <FontAwesomeIcon
                icon={faWhatsapp}
                style={{
                  color: "white",
                }}
              />{" "}
              Soporte por WhatsApp
            </a>
          </CardFooter>

          {/* <CardBody
            style={{
              maxHeight: "300px",
              overflowY: "auto",
            }}
            ref={cardBodyRef}
          >
            {chatMessages.map((chatMessage, index) => {
              return (
                <div key={index} className="">
                  {chatMessage.from === "user" ? (
                    <div
                      key={index}
                      className="d-flex w-100 align-items-center justify-content-end"
                    >
                      <div
                        style={{
                          backgroundColor: "#128C7E",
                          color: "white",
                          padding: "0.5rem",
                          borderRadius: "10px",
                          marginBottom: "0.5rem",
                        }}
                      >
                        {chatMessage.message}
                      </div>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="d-flex w-100 align-items-center justify-content-start"
                    >
                      <div
                        className="gpt-message"
                        style={{
                          backgroundColor: "#25D366",
                          color: "white",
                          padding: "0.5rem",
                          borderRadius: "10px",
                          marginBottom: "0.5rem",
                        }}
                      >
                        {chatMessage.message}
                      </div> 
                      <div
                        className="gpt-message"
                        style={{
                          backgroundColor: "#25D366",
                          color: "white",
                          padding: "0.5rem",
                          borderRadius: "10px",
                          marginBottom: "0.5rem",
                        }}

                        dangerouslySetInnerHTML={{ __html: chatMessage.message }}
                      />
                    </div>
                  )}
                </div>
              );
            })}

            {loadingChat && (
              <div className="d-flex w-100 align-items-center justify-content-start">
                <div
                  style={{
                    backgroundColor: "#25D366",
                    color: "white",
                    padding: "0.5rem",
                    borderRadius: "10px",
                    marginBottom: "0.5rem",
                  }}
                >
                  Escribiendo...
                </div>
              </div>
            )}
          </CardBody> */}

          {/* <CardFooter className="d-flex align-items-center justify-content-between border-top">
            <FalconInput
              placeholder="Escribe tu mensaje..."
              value={message}
              disabled={loadingChat}
              onChange={(e) => {
                setMessage(e);
              }}
            />
            <Button
              color="primary"
              onClick={() => {
                handleSendMessage();
              }}
            >
              Enviar
            </Button>
          </CardFooter> */}
        </Card>
      </Collapse>

      <Button
        className={`${!isOpen && "pulse-lg-blue"} p-0`}
        color="primary"
        style={{
          width: "3.7rem",
          height: "3.7rem",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        {isOpen ? (
          <FontAwesomeIcon
            icon={faXmark}
            style={{
              color: "white",
              fontSize: "2rem",
              padding: "0rem",
              margin: "0rem",
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faMessageQuestion}
            style={{
              color: "white",
              fontSize: "2rem",
              padding: "0rem",
              margin: "0rem",
            }}
          />
        )}
      </Button>
      {/* <a
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "70px",
          height: "70px",
          flexDirection: "row",
          borderRadius: "50%",
          backgroundColor: "#25D366",
          boxShadow: "-2px 4px 15px rgba(0,0,0,0.4)",
        }}
        target="__blank"
        href={`https://wa.me/528128826598?text=*No%20borrar%20esta%20informaci%C3%B3n*%0A${companyData?.companyName}%0AcID:%20${companyID}%0Acliente:%20${userName}%0AID:%20${userID}%0A*No%20borrar%20esta%20informaci%C3%B3n*%0A`}
        className="pulse-lg"
      >
        <FontAwesomeIcon
          icon={faWhatsapp}
          style={{
            color: "white",
            fontSize: "3.5rem",
          }}
        />
      </a> */}
    </div>
  );
};

export default CustomerSupportWhatsapp;
