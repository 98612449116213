import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";

const RawBlogs = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const firestore = firebase.firestore();

  useEffect(() => {
    if (loading) {
      let fullData = [];
      firestore
        .collection("blog")
        .where("published", "==", true)
        .orderBy("Timestamp", "desc")
        .limit(10)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            fullData.push({ ...doc.data() });
          });
        })
        .then(() => {
          fullData.sort((a, b) => b.Timestamp - a.Timestamp);
          setData(fullData);
          setLoading(false);
          return { loading, data };
        });
    }
  }, [loading]);
  return { loading, data };
};

export default RawBlogs;
