import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faScanner } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Badge } from "reactstrap";
import Flex from "../common/Flex";
library.add(fas, faScanner);
const UpgradePlanMenuItem = ({ route }) => {
  const plans = ["Basico", "Estandar", "Premium"];

  return (
    <Flex align="center my-0 py-0">
      {route.icon && (
        <span className="nav-link-icon faded">
          <FontAwesomeIcon icon={route.icon} />
        </span>
      )}
      <span className="nav-link-text faded">{route.name}</span>
      <Badge
        color={route.planRequired == 2 ? "soft-info" : "soft-success"}
        pill
        className="ml-2"
        style={{ fontSize: "0.6rem" }}
      >
        {plans[route.planRequired - 1]}
      </Badge>
    </Flex>
  );
};

UpgradePlanMenuItem.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(UpgradePlanMenuItem);
