import React, { useContext } from "react";
import UserDataContext from "../../context/Context.js";

const NewTag = (props) => {
  const { isDark } = useContext(UserDataContext);

  return (
    <div
      style={{
        padding: "2px 4px",
        borderRadius: "4px",
        display: "inline-block",
      }}
      className={
        isDark
          ? "text-light mr-3 text-sans-serif font-weight-semi-bold bg-primary fs--2 "
          : "text-light mr-3 text-sans-serif font-weight-semi-bold bg-primary fs--2"
      }
    >
      NUEVO
    </div>
  );
};

export default NewTag;
