import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
//Firebase imports
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";

//Firebase compat imports
import "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/analytics";

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import Main from "./Main";
import "./helpers/initFA";
import firebase from "firebase/compat/app";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: "qr-rewards.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export const firestore = getFirestore();

export const auth = getAuth();

const functions = firebase.functions();

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(reCaptchaKey),
  isTokenAutoRefreshEnabled: true,
});

// Cloud functions
const isSubActiveFunc = functions.httpsCallable("verifySubNewFunc");
const getCustomerPaymentMethodsCF = functions.httpsCallable(
  "getCustomerPaymentMethods",
);

// Exported Functions
export const createCompanyCF = functions.httpsCallable("Company-createCompany");

export const createCustomerPortalSession = functions.httpsCallable(
  "createCustomerPortalSession",
);

/**
 * Function to create a company
 * @return {Promise<any>}
 */
export async function getCustomerPaymentMethods() {
  try {
    const paymentMethods = await getCustomerPaymentMethodsCF();
    return paymentMethods.data;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Function to verify if the user has an active subscription
 * @return {Promise<Boolean>} True if the user has an active subscription
 */
export async function isSubActive() {
  try {
    const sub = await isSubActiveFunc();
    return sub.data;
  } catch (error) {
    console.log(error);
  }
}

const root = createRoot(document.getElementById("main"));

root.render(
  <Main>
    <App />
  </Main>,
);
