import classNames from "classnames";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Navbar,
  Spinner,
} from "reactstrap";
import { navbarBreakPoint } from "../../config";
import AppContext from "../../context/Context";
import { auth, createCompanyCF, getCustomerPaymentMethods, isSubActive } from "../../index";
import routes from "../../routes";
import routesPayment from "../../routesPayment";
import Flex from "../common/Flex";
import Logo from "./Logo";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import ToggleButton from "./ToggleButton";
import "./navbarVertical.css";
const NavbarVertical = ({
  navbarStyle,
  permissionLevel,
  billingUser,
  companyID,
  isRegisterBannerOpen,
  registeredPlan,
  isPos,
}) => {
  const history = useHistory();
  const navBarRef = useRef(null);
  const [subActive, setSubActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalRef, setModalRef] = useState(false);
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [plan, setPlan] = useState("");
  const firestore = firebase.firestore();
  const functions = firebase.functions();
  const { showBurgerMenu, isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed } =
    useContext(AppContext);
  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  const createCustomerPortalSessionFX = functions.httpsCallable("createCustomerPortalSession");
  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add("navbar-vertical-collapsed");
  }

  const redirectCustomerPortal = () => {
    setIsLoading(true);
    createCustomerPortalSessionFX()
      .then((res) => {
        const { url } = res.data;
        window.open(url, "_blank");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  /**
   * Function that verify if the user has a company, subscription and payment methods
   * @return {Promise<void>}
   */
  const verifier = async (uid) => {
    const userDoc = await firestore.doc(`posUsers/${uid}`).get();
    const { companyID, registrationComplete } = userDoc.data();
    const stripeBilling = billingUser ? true : false;
    const plan = registeredPlan ? registeredPlan : "";
    try {
      // If the user is logged in
      if (uid) {
        const sub = await isSubActive();
        if (stripeBilling) {
          //If the user has an active subscription
          if (sub) {
            setSubActive(true);
            console.log("User has an active subscription");
            // If the user has a company
            if (companyID) {
              console.log("User has a company");
              const paymentMethods = await getCustomerPaymentMethods();
              // If the user has a payment method
              if (paymentMethods.data.length > 0) {
                // // If the user has completed the registration
                // if (registrationComplete === true) {
                //   history.push("/");
                // }
                console.log("User has a payment method");
              } else {
                // If the user does not have a payment method
                console.log("Need to add a payment method");
                setModal(true);
              }
            } else {
              console.log("User does not have a company");
              // If the user does not have a company
              await createCompanyCF();
              toast.success("¡Tu negocio ha sido creado con éxito!");
              history.push("/");
            }
          } else {
            // If the user does not have an active subscription
            setModal(true);
            setContent("past");
          }
        } else {
          if (plan === "transfer") {
            console.log("User is from stripe transfer");
          }
          // User is from old transfer when we dont have stripe
          setSubActive(true);
          console.log("User is from transfer");
        }
      } else {
        // If the user is not logged in
        history.push("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    verifier(auth.currentUser.uid).then(() => {
      setIsLoading(false);
    });
  }, []);
  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add("navbar-vertical-collapsed-hover");
      }, 100);
    }
  };
  const copyToClipboard = (text) => {
    let dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };
  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames("navbar-vertical navbar-glass", {
        [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
      })}
      light
      id={isRegisterBannerOpen ? "navbar-vertical" : ""}
    >
      {/*Modal to show if the user has a past due subscription or no payment method*/}
      <Modal
        centered={true}
        isOpen={modal}
        className="p4"
        toggle={(e) => {
          e.preventDefault();
          setModal(!modal);
        }}
      >
        {content === "past" ? (
          <div>
            <ModalHeader>
              <h3>El último cobro no pudo realizarse.</h3>
            </ModalHeader>
            <ModalBody>
              <div>
                <p>
                  Se intentó recientemente hacer el cobro de tu subscripción a Swirvle sin éxito. Si
                  necesitas cambiar la forma de pago, ingresa al menú - Pagos - Forma de Pago.
                </p>
              </div>
            </ModalBody>
          </div>
        ) : (
          <div>
            <ModalHeader>
              <h3>¡No tienes un medio de pago registrado!</h3>
            </ModalHeader>
            <ModalBody>
              <div>
                <p>No se tiene un medio de pago registrado para pagar tu plan en Swirvle.</p>
              </div>
            </ModalBody>
          </div>
        )}
        <ModalFooter>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setModal(false);
            }}
          >
            Cerrar
          </Button>
          {content === "past" && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                redirectCustomerPortal();
              }}
            >
              Ir a Pagar
            </Button>
          )}
        </ModalFooter>
      </Modal>
      {/*Modal to show the referral link*/}
      {/* <Modal
        centered={true}
        isOpen={modalRef}
        className="p4"
        toggle={(e) => {
          e.preventDefault();
          setModalRef(!modalRef);
        }}
      >
        <div>
          <ModalHeader className="d-flex justify-content-center align-items-center">
            <h3>¡Refiere a un amig@!</h3>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                color: "#525252",
              }}
            >
              <p>
                Recibe $200 MXN de descuento en tu proxima mensualidad por cada negocio que
                refieras.
              </p>
              <p>
                Además, ¡Tu referido también gana! Recibirá de bienvenida un 10% de descuento en su
                primera mensualidad!
              </p>
              <p>Tu enlace para invitar:</p>
              <InputGroup className="my-3">
                <Input type="text" value={`https://dashboard.swirvle.com/referral/${companyID}`} />
                <Button
                  color="primary"
                  onClick={() => {
                    copyToClipboard(`https://dashboard.swirvle.com/referral/${companyID}`);
                    toast.success("Enlace copiado a portapapeles");
                  }}
                >
                  <FontAwesomeIcon icon="copy" size="sm" /> Copiar
                </Button>
              </InputGroup>
            </div>
          </ModalBody>
        </div>
        <ModalFooter>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setModalRef(!modalRef);
            }}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal> */}
      <Flex align="center">
        <ToggleButton
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
        />
        <Logo at="navbar-vertical" width={40} />
      </Flex>
      <Collapse
        navbar
        isOpen={showBurgerMenu}
        className="scrollbar"
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          HTMLClassList.remove("navbar-vertical-collapsed-hover");
        }}
        style={
          navbarStyle === "vibrant" && {
            // backgroundImage: `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2)`,
            backgroundColor: "#232e3c",
          }
        }
      >
        <Nav navbar vertical>
          {isLoading ? (
            <Spinner style={{ display: "flex", justifyContent: "center" }} />
          ) : (
            <NavbarVerticalMenu
              routes={subActive ? routes : routesPayment}
              permissionLevel={permissionLevel}
              billingUser={billingUser}
              companyPlan={registeredPlan}
              isPos={isPos}
            />
          )}
        </Nav>
        {/* <div className="settings px-3 px-xl-0">
          <div className="navbar-vertical-divider">
            <hr className="navbar-vertical-hr my-2" />
          </div>
          <Button
            // tag={"a"}
            // href="https://themes.getbootstrap.com/product/falcon-admin-dashboard-webapp-template-react/"
            // target="_blank"
            color="primary"
            size="sm"
            block
            className="my-3 btn-purchase btn-referidos"
            style={{
              borderRadius: "30px",
            }}
            onClick={(e) => {
              e.preventDefault();
              setModalRef(true);
            }}
          >
            Refiere a un amig@
          </Button>
        </div> */}
        {/* </Scrollbar> */}
      </Collapse>
    </Navbar>
  );
};
NavbarVertical.protoTypes = {
  navbarStyle: PropTypes.string,
};
NavbarVertical.defaultProps = {
  navbarStyle: "transparent",
};
export default NavbarVertical;
